import React from 'react'
import { Link } from 'gatsby'
import config from '../../siteConfig'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Row, Col, ProductBookCard, Button, Video } from '../../components/global'

const H113 = () => (
  <Layout>
    <SEO title="The DC Motor Troubleshooting Trainer, H-113" />
    <div className="container container-last">
      <h1>
        The DC Motor
        <br />
        Troubleshooting Trainer, H-113
      </h1>
      <p className="boost">
        Learn to quickly troubleshoot and identify advanced ground-side problems.
      </p>
      <hr />
      <Row>
        <Col width="30%" align="left">
          <ProductBookCard
            title="The DC Motor Troubleshooting Trainer, H-113"
            titleSmall={true}
            step="Hands-on Kit"
            animate={false}
            price={199.0}
          />
        </Col>
        <Col width="68%" align="left">
          <p className="demote">
            <strong>
              Before purchasing this product, make sure you own{' '}
              <Link to="/products/h-111a">The Starter Kit H-111A</Link> as it is required to own
              this kit.
            </strong>
          </p>
          <p>
            Students learn characteristics of analog ohmmeters, their differences and operating
            characteristics to understand which ohmmeter could be used to test a brush less DC motor
            before ordering a new one. Students also learn to use the Diode Test feature of a DMM to
            test a brush less DC motor.
          </p>
          <p>
            <Button
              href="/products/circuit-board-trainer-inventory-clearance"
              text="See Purchasing Options"
              type="secondary"
            />
          </p>
        </Col>
      </Row>
      <hr />
      <p className="boost">See The DC Motor Troubleshooting Trainer, H-113 in action.</p>
      <Video
        title="See The DC Motor Troubleshooting Trainer, H-113 in action"
        src="https://www.youtube.com/embed/1SSfQ7qHnC0"
      />
      <hr />
      <p>
        After mastering the first 37 DC Motor circuit problems there are 5 Advanced Electrical
        Problems to present additional challenge to Techs who are ready for more of an advance test
        of their troubleshooting ability. That's a total of 42 individual electrical troubleshooting
        problems.
      </p>
      <Row>
        <Col width="49%" align="left">
          <p>Troubleshooting DC Motor Circuit Kit H-113 module contains the following:</p>
          <ul>
            <li>1 - DC Motor Circuit Board, H-PCB03</li>
            <li>1 - Resistor Bag H-RB03</li>
            <li>1 - Home-Study Student Man. H-WB113</li>
            <li>1 - Instructor Guide H-IG113</li>
          </ul>
        </Col>
        <Col width="49%" align="left">
          <Image file="h-113.jpg" />
        </Col>
      </Row>
      <hr />
      <h2>What Next?</h2>
      <p>
        Continue developing your troubleshooting skills and learning more about auto/truck
        electrical/electronic troubleshooting with the following live circuit troubleshooting
        trainers!
      </p>
      <ul>
        <li>
          <Link to="/products/h-115">H-115(S), Relay Circuit Troubleshooting</Link>
        </li>
        <li>
          <Link to="/products/h-116">Wire Harness Troubleshooting Trainer H-116</Link>
        </li>
        <li>
          <Link to="/products/h-200">CAN Bus Troubleshooting Trainer H-200(S)</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default H113
